<template>
  <div>
    <div class="desktop-present wrap" v-bind:class="{ othernav: navHeightShow, duck: navDuckShow }">
      <div class="title">
        <div class="title-log" @click="onGoHome">
          <div class="title-bg"></div>
          <!-- <div class="title-text">
            <div class="title-large">中国县市图片网</div>
            <div class="title-small">全国县市区融媒体中心视觉产品共享平台</div>
          </div> -->
        </div>
        <div class="nav">
          <div class="nav-list" v-if="navList">
            <div class="nav-detail" v-for="(k, navIndex) in navList" :key="k + 'nav_' + navIndex" :class="{ active: clickIndex == navIndex }">
              <a :href="k.url" target="_blank">{{ k.name }}</a>
            </div>
          </div>
          <!-- <div class="search">
            <div class="search-input">
              <el-input class="sinput" v-model="search" @keyup.enter.native="onSearch"></el-input>
              <div class="search-icon el-icon-search" @click="onSearch"></div>
            </div>
          </div> -->
          <div class="right-logo">
            <div class="fenhui">
              <a href="https://xuehui.chinaphotos.cn/" target="_blank">中国新闻摄影学会</a>
            </div>
            <div class="fenhui">
              <a href="https://xuehui.chinaphotos.cn/" target="_blank">县市传媒分会官方网站</a>
            </div>
          </div>
        </div>
        <div class="nav-right">
          <div class="logon">
            <div class="login-btn">
              <div class="login" v-if="!logined" @click="onLogoin">登录</div>
              <div class="register" v-if="!logined" @click="onRegister">注册</div>
            </div>
            <div class="logoin" v-if="logined">
              <div class="mine">
                <a href="myPost.html" target="_blank" class="myHover">我的稿件</a>
              </div>
              <div class="upload" v-if="syncData.upPicStores.length != 0" @click="onUploadPic">上传稿件</div>
              <div class="upload" v-if="syncData.upVideoStores.length != 0" @click="onUploadVideo">上传视频</div>
              <div class="logout" @click="onOutLogin">退出</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 二维码进小程序 -->
      <div class="wxQR">
        <div class="wx-out">
          <div class="wx-text">手机传稿</div>
          <div class="wx-img">
            <div class="wxqr"></div>
          </div>
        </div>
      </div>
      <!-- 活动的评选 -->
      <div
        class="activitySelect-con"
        :class="{
          active: activityTopicSelectShow == true,
        }"
      >
        <div class="activitySelect" v-for="(k, selectIndex) in activitySelectList" :key="k + 'as_' + selectIndex">
          <div class="activity-name">您有需要参加的评选活动</div>
          <div class="activity-con">
            <div><span>评选</span> {{ k.voteName }}</div>
            <div><span>来自活动</span> {{ k.activityTopicShortTitle }}</div>
            <div class="activity-btn">
              <div>
                <a :href="'vote.html?id=' + k.id + '&type=' + k.type" target="_blank">马上参加</a>
              </div>
              <div @click="onAfter(selectIndex)">稍后再说</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-present wrap">
      <div class="title">
        <div class="title-log" @click="onGoHome">
          <div class="title-bg"></div>
          <!-- <div class="title-text">
            <div class="title-large">中国县市图片网</div>
            <div class="title-small">全国县市区融媒体中心视觉产品共享平台</div>
          </div> -->
        </div>
        <div class="nav">
          <div class="nav-list" v-if="navList">
            <div class="nav-detail" v-for="(k, navIndex) in navList" :key="k + 'nav_' + navIndex" :class="{ active: clickIndex == navIndex }">
              <a :href="k.url" target="_blank">{{ k.name }}</a>
            </div>
          </div>
          <div class="search">
            <div class="search-input">
              <el-input class="sinput" v-model="search" @keyup.enter.native="onSearch"></el-input>
              <div class="search-icon el-icon-search" @click="onSearch"></div>
            </div>
          </div>
        </div>
        <div class="nav-right">
          <!-- <div class="fenhui">
            <a href="https://xuehui.chinaphotos.cn/" target="_blank">中国新闻摄影学会</a>
          </div>
          <div class="fenhui">
            <a href="https://xuehui.chinaphotos.cn/" target="_blank">县市传媒分会官方网站</a>
          </div> -->
          <div class="logon">
            <div v-if="!logined" @click="onLogoin">登录</div>
            <div v-if="!logined" @click="onRegister">注册</div>
            <div class="logoin" v-if="logined">
              <div class="mine">
                <a href="myPost.html" target="_blank" class="myHover">我的稿件</a>
              </div>
              <div class="upload" v-if="syncData.upPicStores.length != 0" @click="onUploadPic">上传稿件</div>
              <div class="upload" v-if="syncData.upVideoStores.length != 0" @click="onUploadVideo">上传视频</div>
              <div class="logout" @click="onOutLogin">退出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="用户登录" :visible="logoedShow" width="50rem" class="dialog" :before-close="handleClose">
      <div class="logo-in">
        <div class="name"><span>用户名</span><el-input v-model="logoName" placeholder="请输入用户名"></el-input></div>
        <div class="pwd"><span>密码：</span><el-input v-model="passWord" placeholder="请输入密码" show-password></el-input></div>
        <div class="code">
          <span>验证码：</span>
          <el-input v-model="code" placeholder="请输入验证码" clearable></el-input>
          <img :src="codeImg" alt="" class="yan-img" v-if="!logined" @click="onRetrieveCode" />
        </div>
        <div class="tip" v-if="errorMessage ? errorMessage : ''">提示：{{ errorMessage }}</div>
        <el-button class="backMsg" @click="onForgetpswd">找回密码</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoedShow = false">取 消</el-button>
        <el-button type="primary" @click="onSureLogo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import sha1 from 'js-sha1'

export default {
  name: 'Home',
  components: {},
  props: ['front', 'height'],
  data: function () {
    return {
      logoName: '', // 登录名
      passWord: '', // 密码
      code: '', // 验证码
      search: '', // 搜索的条件
      loginState: 1, // 是否登录的状态
      WXVisible: false, // 微信的二维码
      logoShow: false, // 登录框的显示
      noUpload: false, // 是否登录
      mobileUploadShow: false, // 手机端上传下级的展开
      logined: false,
      codeImg: '', // 验证码的图片路径
      loading: false, // 加载的loading效果
      options: [
        {
          value: 1,
          label: '关键词',
        },
        {
          value: 2,
          label: '图片',
        },
      ],
      searchkey: 1,
      navList: [
        { name: '专题分类', url: '/catalog.html?title=专题分类' },
        {
          name: '视频专区',
          url: '/videoCatalog.html?title=视频',
        },
        {
          name: '摄影师专栏',
          url: '/userShow.html',
        },
        {
          name: '全国融媒联盟',
          url: '/localNodes.html',
        },
        {
          name: '活动评比',
          url: '/activity.html',
        },
        {
          name: '分会动态',
          url: 'https://xuehui.chinaphotos.cn/',
        },
      ],
      clickIndex: -1,
      logoedShow: false, // 登录的弹框
      upShow: false, // 上传类别
      myShow: false, // 我的类型
      syncData: {},
      ispassPicShow: false, // 是否通过且显示上传稿件
      activityTopicSelectShow: false,
      activitySelectList: [],
      navDuckShow: false,
      navHeightShow: false, // Nav是否占高度
      errorMessage: undefined,
    }
  },
  created() {
    var thiz = this
    this.syncfresh()
    console.log('front', this.front)
    if (thiz.front == undefined) {
      thiz.navHeightShow = false
    } else {
      thiz.navHeightShow = true
    }
  },
  mounted() {
    var thiz = this
    this.load()
    this.refreshCode()
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 600) {
        this.navDuckShow = true
      } else {
        this.navDuckShow = false
      }
    },
    p(s) {
      return s < 10 ? '0' + s : s
    },
    // sync刷新
    syncfresh() {
      var thiz = this
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.loginState = 2
          thiz.logined = true
          if (data.approveStatusName == '等待审批') {
            thiz.noUpload = false
          } else if (data.approveStatusName == '已通过' && data.upPicStores.length != 0) {
            thiz.noUpload = true
            thiz.isSelect()
          } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
            thiz.noUpload = true
            thiz.isSelect()
          }
          thiz.syncData = data
        })
        .catch(() => {
          thiz.loginState = 1
        })
    },
    // 检查是否有可以评选的活动
    isSelect() {
      var thiz = this
      RD.activityTopic()
        .checkVoter()
        .then((data) => {
          if (data.length != 0) {
            thiz.activityTopicSelectShow = true
          }
          thiz.activitySelectList = data
        })
    },
    load() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    // 回到首页
    onGoHome() {
      let routerUrl = this.$router.resolve({
        path: '/index.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    onRegister() {
      let routerUrl = this.$router.resolve({
        path: '/register.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    refreshCode() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    onRetrieveCode() {
      this.refreshCode()
    },
    // 登录
    onLogoin() {
      var thiz = this
      thiz.logoedShow = true
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    handleClose() {
      var thiz = this
      thiz.logoedShow = false
    },
    onSureLogo() {
      var thiz = this
      thiz.loading = true
      if (thiz.logoName == '') {
        thiz.$rtm.message.notify('用户名不能为空')
        return
      }
      if (thiz.passWord == '') {
        thiz.$rtm.message.notify('密码不能为空')
        return
      }
      var weekPassword = false
      if (thiz.code) {
        RD.pure()
          .user()
          .login(thiz.code, thiz.logoName, sha1(thiz.passWord), weekPassword)
          .then(() => {
            thiz.logined = true
            thiz.loginState = 2
            thiz.code = ''
            thiz.loading = false
            location.reload()
            thiz.isSelect()
          })
          .catch((error) => {
            thiz.load()
            thiz.passWord = ''
            thiz.$rtm.message.notify(error.msg)
            thiz.errorMessage = error.msg
            thiz.code = ''
            thiz.loading = false
          })
      } else {
        thiz.$rtm.message.notify('请输入验证码再登录')
      }
    },
    onOutLogin() {
      RD.user()
        .logout()
        .then(() => {
          location.reload()
        })
    },
    // 稍后再说
    onAfter(index) {
      // this.activityTopicSelectShow = false
      this.activitySelectList.splice(index, 1)
    },
    // 搜索
    onSearch() {
      var thiz = this
      var parentName = this.$parent.$options.name
      if (parentName != 'search') {
        this.$router.push({
          path: 'search.html',
          query: { keyword: thiz.search },
        })
      } else {
        this.$parent.search(thiz.search)
        this.$router.push({
          path: 'search.html',
          query: { keyword: thiz.search },
        })
      }
    },
    // 找回密码
    onForgetpswd() {
      let routerUrl = this.$router.resolve({
        path: '/forgetPassWord.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    // 上传图片
    onUploadPic() {
      if (this.logined) {
        let routerUrl = this.$router.resolve({
          path: '/upPost.html',
        })
        window.open(routerUrl.href, '_blank')
      } else {
        this.$message('请先登录账户再上传')
      }
    },
    // 上传视频
    onUploadVideo() {
      console.log('shsmx')
      if (this.logined) {
        let routerUrl = this.$router.resolve({
          path: '/uploadVideo.html',
        })
        window.open(routerUrl.href, '_blank')
      } else {
        this.$message('请先登录账户再上传')
      }
    },
  },
}
</script>
<style lang="less">
.sinput {
  .el-input__inner {
    outline: none !important;
    border: 0px !important;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}
</style>
<style lang="less" scoped>
.desktop-present.wrap {
  height: auto;
  width: 100%;
  background-color: #000;
  transition: all 500ms ease-in-out;
  .top {
    height: 5rem;
    background: #ff8c00;
    .top-con {
      width: 120rem;
      margin: 0 auto;
      .top-right {
        width: 90%;
        text-align: right;
        height: 5rem;
        position: relative;
        .loged {
          width: 26rem;
          height: 4.2rem;
          display: flex;
          justify-content: flex-end;
          position: absolute;
          right: 24rem;
          top: 0.25rem;
          text-align: right;
          color: #fff;
          a {
            text-decoration: none;
            color: black;
          }
          a:hover {
            color: red;
          }
          .logo-icon {
            width: 4.5rem;
            height: 5rem;
            font-size: 2.3rem;
            line-height: 5rem;
          }
          .logo {
            width: 5rem;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 1.6rem;
            border-right: 1px solid #fff;
            margin-top: 1.2rem;
            cursor: pointer;
            text-align: center;
          }
          .logo:hover {
            color: #000;
          }
          .register {
            width: 5rem;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 1.6rem;
            border-right: 1px solid #fff;
            margin-top: 1.2rem;
            cursor: pointer;
            text-align: center;
          }
          .register:hover {
            color: #000;
          }
          .upload:hover {
            .upDrow {
              display: inline-block;
            }
          }
          .mine {
            height: 2.5rem;
            width: 8.6rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            margin-top: 1.2rem;
            cursor: pointer;
            a {
              color: #fff;
            }
          }
          .upload {
            height: 2.5rem;
            width: 10rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            margin-top: 1.2rem;
            z-index: 90;
            .uphover {
              line-height: 2.5rem;
              font-size: 1.5rem;
              text-align: center;
              cursor: pointer;
            }
            .upDrow {
              height: auto;
              display: none;
              width: auto;
              min-width: 6.6rem;
              margin: 0 auto;
              border-radius: 0.48rem;
              border: 1px solid red;
              color: #e63439;
              background-color: white;
              z-index: 100;
              .uploadPic {
                height: 2.5rem;
                line-height: 2.5rem;
                font-size: 1.3rem;
                cursor: pointer;
                text-align: center;
              }
              .uploadVideo {
                height: 2.5rem;
                line-height: 2.5rem;
                font-size: 1.3rem;
                text-align: center;
                margin-top: 0.48rem;
                cursor: pointer;
              }
            }
          }
          .mymine:hover {
            .mydrow {
              display: inline-block;
            }
          }
          .mymine {
            height: 2.5rem;
            width: 8.6rem;
            min-width: 4.2rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            text-align: center;
            margin-top: 1.2rem;
            z-index: 90;
            a {
              color: #fff;
            }
            .myHover {
              height: auto;
              cursor: pointer;
            }
            .mydrow {
              height: auto;
              display: none;
              width: auto;
              min-width: 6.6rem;
              margin: 0 auto;
              border-radius: 0.48rem;
              border: 1px solid red;
              color: #e63439;
              background-color: white;
              cursor: pointer;
              .myVideo {
                height: 2.5rem;
                line-height: 2.5rem;
                font-size: 1.3rem;
                text-align: center;
                margin-top: 0.4rem;
                cursor: pointer;
              }
              .myPost {
                font-size: 1.3rem;
              }
            }
          }

          .logout {
            width: 8.9rem;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            margin-top: 1.2rem;
            cursor: pointer;
          }
        }
        .search {
          position: absolute;
          right: 0px;
          top: 1.5rem;
          width: auto;
          height: 2.5rem;
          .search-input {
            width: 21rem;
            overflow: hidden;
            height: 100%;
            background-color: #fff;
            border-radius: 1.2rem;
            position: relative;
            .sinput {
              height: 100%;
            }
            .search-icon {
              position: absolute;
              right: 0.8rem;
              top: 3px;
              height: 2.5rem;
              width: 1.8rem;
              font-size: 2rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .title {
    display: flex;
    height: 10rem;
    position: relative;
    width: 120rem;
    margin: 0 auto;
    .title-log {
      width: 30.5rem;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0rem;
      cursor: pointer;
      display: flex;
      .title-bg {
        height: 80%;
        width: 100%;
        background: no-repeat url(../assets/logo.png);
        background-size: contain;
        margin-top: 1.6rem;
      }
      .title-text {
        color: #fff;
        margin-top: 2.5rem;
        margin-left: 1rem;
        .title-large {
          font-size: 2.2rem;
          height: 3rem;
          line-height: 3rem;
          letter-spacing: 10px;
          margin-left: 1px;
        }
        .title-small {
          font-size: 1.2rem;
          text-align: left;
          transform: scale(1, 1.6);
          -ms-transform: scale(1, 1.6);
          -webkit-transform: scale(1, 1.6);
          -moz-transform: scale(1, 1.6);
          -o-transform: scale(1, 1.6);
        }
      }
    }
    .nav {
      width: 89rem;
      height: 100%;
      position: absolute;
      left: 31.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-list {
        display: flex;
        min-width: 59rem;
        max-width: 64rem;
        flex: 1;
        height: 10rem;
        line-height: 10rem;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        .nav-detail {
          font-size: 1.8rem;
          cursor: pointer;
          position: relative;
          text-align: left;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            text-decoration: none;
            padding: 0px 0.8rem;
          }
          .nav-detail-subs {
            position: absolute;
            top: 8.3rem;
            left: 0px;
            width: 10rem;
            z-index: 99;
            overflow: hidden;
            .nav-detail-seq {
              font-size: 1.5rem;
              height: 0px;
              background-color: #ff8c00;
              color: #fff;
              transition: 200ms all ease-in-out;
              cursor: pointer;
              a {
                color: #fff;
                text-decoration: none;
                height: 0px;
              }
            }
          }
        }
        .nav-detail:hover {
          color: #fff;
          background: #ff8c00;
          a {
            color: #fff;
          }
          .nav-detail-subs {
            display: block;
          }
          .nav-detail-seq {
            height: 2.5rem;
            line-height: 2.5rem;
            margin: 0.8rem 0px 0px 0px;
            a {
              height: 2.5rem;
              color: #fff;
            }
          }
        }
        .nav-detail.active {
          color: red;
        }
      }
      .search {
        width: 13rem;
        height: 2.5rem;
        .search-input {
          width: 13rem;
          overflow: hidden;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 1.2rem;
          position: relative;
          .sinput {
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
          }
          .search-icon {
            position: absolute;
            right: 0.8rem;
            top: 3px;
            height: 2.5rem;
            width: 1.8rem;
            font-size: 2rem;
            cursor: pointer;
          }
        }
      }
      .right-logo {
        margin-top: -2rem;
        .fenhui {
          font-size: 1.6rem;
          color: #fff;
          ::v-deep a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
    .nav-right {
      position: absolute;
      right: 0rem;
      bottom: 0rem;
      cursor: pointer;

      div {
        font-size: 1.6rem;
        color: #fff;
      }
      .fenhui {
        ::v-deep a {
          text-decoration: none;
          color: #fff;
        }
      }
      .logon {
        width: 50rem;
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        height: 3.8rem;
        line-height: 3.8rem;
        text-align: right;
        .login-btn {
          display: flex;
          justify-content: flex-end;
          .login,
          .register {
            width: 5rem;
            text-align: center;
          }
          .login:hover {
            background-color: #999693;
            color: red;
          }
          .register:hover {
            background-color: #999693;
            color: red;
          }
        }
        .el-button {
          height: 3rem;
          line-height: 0rem;
          width: 7rem;
        }
        .logoin {
          display: flex;
          margin-top: 0.5rem;
          justify-content: flex-end;

          div {
            height: 2rem;
            line-height: 2rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 1.5rem;
            padding: 4px;
            font-size: 1.4rem;
          }
          .mine {
            font-size: 1.4rem;
            a {
              width: 100%;
              height: 100%;
              display: inline-block;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .nav-content {
    width: 100%;
    height: 4.2rem;
    display: flex;
    background-color: #898989;
    position: relative;
    color: #fff;
    .nav-log {
      width: 2.5rem;
      height: 4.2rem;
      font-size: 4.2rem;
      display: flex;
      justify-content: flex-start;
      div {
        width: 2.5rem;
        height: 4.2rem;
        font-size: 2.5rem;
        line-height: 4.2rem;
      }
    }
    .nav-number {
      width: 20rem;
      height: 4.2rem;
      line-height: 4.2rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 0.8rem;
      .fapian {
        width: 5.8rem;
        display: inline-block;
        height: 2.5rem;
        line-height: 2.5rem;
        color: red;
        padding-right: 0.42rem;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: left;
      }
    }
  }
  .activitySelect-con {
    height: auto;
    position: fixed;
    bottom: -40vh;
    right: 2rem;
    z-index: 9999;
    transition: all 2000ms ease-in-out;
    .activitySelect {
      width: 25rem;
      height: auto;
      border: 1px solid #ff8c00;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1rem;
      .activity-name {
        height: 4rem;
        line-height: 4rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #ccc;
      }
      .activity-con {
        font-size: 1.6rem;
        div {
          height: 4rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .activity-btn {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-around;
        div {
          background: #ff8c00;
          color: #fff;
          height: 3rem;
          line-height: 3rem;
          font-size: 1.6rem;
          width: 9rem;
          text-align: center;
          border-radius: 0.5rem;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  .activitySelect-con.active {
    bottom: 2rem;
  }

  .el-button {
    height: 2rem;
    font-size: 1.2rem;
  }

  .wxQR {
    position: fixed;
    top: 20rem;
    right: -19rem;
    width: 22rem;
    height: 18.8rem;
    z-index: 1;
    transition: all 200ms ease-in-out;
    transition-delay: 1s;
    border: 0.3rem solid rgb(133, 131, 131);
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    overflow: hidden;
    .wx-out {
      display: flex;
      height: 100%;
      .wx-text {
        width: 3rem;
        height: 100%;
        writing-mode: tb-rl;
        text-align: center;
        font-size: 1.5rem;
        background: rgb(102, 100, 100);
        padding-right: 0.5rem;
        color: #fff;
        text-shadow: 0rem 0rem 0.5rem #000;
      }
      .wx-img {
        width: 20.5rem;
        height: 100%;
        .wxqr {
          width: 100%;
          height: 100%;
          background: no-repeat url(../assets/clientQR.jpg);
          background-size: contain;
        }
      }
    }
  }
  .wxQR:hover {
    right: 0rem;
    transition-delay: 0s;
  }
}
.mobile-present {
  height: auto;
  width: 100%;
  background-color: #000;
  transition: all 500ms ease-in-out;
  .title {
    display: flex;
    height: 100px;
    position: relative;
    width: 750px;
    margin: 0 auto;
    .title-log {
      width: 200px;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0rem;
      cursor: pointer;
      display: flex;
      .title-bg {
        height: 80%;
        width: 22%;
        background: no-repeat url(../assets/logo.png);
        background-size: contain;
        margin-top: 1.6rem;
      }
      .title-text {
        color: #fff;
        margin-top: 2.5rem;
        margin-left: 1rem;
        .title-large {
          font-size: 16px;
          height: 30px;
          letter-spacing: 4px;
          margin-left: 1px;
        }
        .title-small {
          font-size: 12px;
          text-align: left;
          height: 37px;
          transform: scale(1, 1.6);
          -ms-transform: scale(1, 1.6);
          -webkit-transform: scale(1, 1.6);
          -moz-transform: scale(1, 1.6);
          -o-transform: scale(1, 1.6);
        }
      }
    }
    .nav {
      width: 420px;
      height: 100%;
      position: absolute;
      left: 31.6rem;
      display: flex;
      .nav-list {
        display: flex;
        width: 310px;
        height: 10rem;
        line-height: 10rem;
        flex-shrink: 0;
        flex-wrap: wrap;
        .nav-detail {
          font-size: 1.8rem;
          cursor: pointer;
          position: relative;
          text-align: left;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #fff;
            text-decoration: none;
            padding: 0px 0.8rem;
          }
          .nav-detail-subs {
            position: absolute;
            top: 8.3rem;
            left: 0px;
            width: 10rem;
            z-index: 99;
            overflow: hidden;
            .nav-detail-seq {
              font-size: 1.5rem;
              height: 0px;
              background-color: #ff8c00;
              color: #fff;
              transition: 200ms all ease-in-out;
              cursor: pointer;
              a {
                color: #fff;
                text-decoration: none;
                height: 0px;
              }
            }
          }
        }
        .nav-detail:hover {
          color: #fff;
          background: #ff8c00;
          a {
            color: #fff;
          }
          .nav-detail-subs {
            display: block;
          }
          .nav-detail-seq {
            height: 2.5rem;
            line-height: 2.5rem;
            margin: 0.8rem 0px 0px 0px;
            a {
              height: 2.5rem;
              color: #fff;
            }
          }
        }
        .nav-detail.active {
          color: red;
        }
      }
      .search {
        // position: absolute;
        // right:-130px;
        // top:-10px;
        width: 120px;
        height: 2.5rem;
        margin-top: 1rem;
        .search-input {
          width: 21rem;
          overflow: hidden;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 1.2rem;
          position: relative;
          margin-top: 2.5rem;
          .sinput {
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
          }
          .search-icon {
            position: absolute;
            right: 0.8rem;
            top: 3px;
            height: 2.5rem;
            width: 1.8rem;
            font-size: 2rem;
            cursor: pointer;
          }
        }
      }
    }
    .nav-right {
      position: absolute;
      right: 0px;
      top: 20px;
      cursor: pointer;

      div {
        font-size: 1.6rem;
        color: #fff;
      }
      .logon {
        width: 50rem;
        position: absolute;
        right: 10px;
        top: 50px;
        height: 4rem;
        line-height: 4rem;
        text-align: right;
        .el-button {
          height: 30px;
          line-height: 0px;
          width: 50px;
        }
        .logoin {
          display: flex;
          margin-top: 0.5rem;
          justify-content: flex-end;
          div {
            height: 2.3rem;
            line-height: 2.3rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 5px;
            padding: 4px;
          }
          .mine {
            a {
              width: 100%;
              height: 100%;
              display: inline-block;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .nav-content {
    width: 100%;
    height: 4.2rem;
    display: flex;
    background-color: #898989;
    position: relative;
    color: #fff;
    .nav-log {
      width: 2.5rem;
      height: 4.2rem;
      font-size: 4.2rem;
      display: flex;
      justify-content: flex-start;
      div {
        width: 2.5rem;
        height: 4.2rem;
        font-size: 2.5rem;
        line-height: 4.2rem;
      }
    }
    .nav-number {
      width: 20rem;
      height: 4.2rem;
      line-height: 4.2rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 0.8rem;
      .fapian {
        width: 5.8rem;
        display: inline-block;
        height: 2.5rem;
        line-height: 2.5rem;
        color: red;
        padding-right: 0.42rem;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: left;
      }
    }
  }
  .activitySelect-con {
    height: auto;
    position: fixed;
    bottom: -40vh;
    right: 2rem;
    z-index: 9999;
    transition: all 2000ms ease-in-out;
    .activitySelect {
      width: 25rem;
      height: auto;
      border: 1px solid #ff8c00;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1rem;
      .activity-name {
        height: 4rem;
        line-height: 4rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #ccc;
      }
      .activity-con {
        font-size: 1.6rem;
        div {
          height: 4rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .activity-btn {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-around;
        div {
          background: #ff8c00;
          color: #fff;
          height: 3rem;
          line-height: 3rem;
          font-size: 1.6rem;
          width: 9rem;
          text-align: center;
          border-radius: 0.5rem;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  .activitySelect-con.active {
    bottom: 2rem;
  }

  .el-button {
    height: 2rem;
    font-size: 1.2rem;
  }

  .wxQR {
    position: fixed;
    top: 20rem;
    right: -19rem;
    width: 22rem;
    height: 18.8rem;
    z-index: 1;
    transition: all 200ms ease-in-out;
    transition-delay: 1s;
    border: 0.3rem solid rgb(133, 131, 131);
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    overflow: hidden;
    .wx-out {
      display: flex;
      height: 100%;
      .wx-text {
        width: 3rem;
        height: 100%;
        writing-mode: tb-rl;
        text-align: center;
        font-size: 1.5rem;
        background: rgb(102, 100, 100);
        padding-right: 0.5rem;
        color: #fff;
        text-shadow: 0rem 0rem 0.5rem #000;
      }
      .wx-img {
        width: 20.5rem;
        height: 100%;
        .wxqr {
          width: 100%;
          height: 100%;
          background: no-repeat url(../assets/clientQR.jpg);
          background-size: contain;
        }
      }
    }
  }
  .wxQR:hover {
    right: 0rem;
    transition-delay: 0s;
  }
}
.dialog {
  z-index: 10000 !important;
  .logo-in {
    text-align: left;
    margin-left: 12%;
    .name,
    .pwd,
    .code {
      margin-bottom: 0.8rem;
      span {
        width: 7.5rem;
        display: inline-block;
      }
      .el-input {
        width: 25rem;
      }
    }
    .backMsg {
      position: absolute;
      left: 5rem;
      bottom: 2rem;
      padding: 1rem;
    }
    .code {
      display: flex;
      flex-wrap: nowrap;
      .code-img {
        width: 10rem;
        height: 4.2rem;
      }
    }
    .tip {
      color: #e63439;
    }
  }
  .el-button {
    height: 4rem;
  }
}
.othernav {
  position: fixed;
  top: 0px;
  height: 10rem !important;
  width: 100% !important;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5) !important;
}
.duck {
  background: rgba(0, 0, 0, 0.85) !important;
  transition: 500ms all ease-in-out;
}

.wx {
  height: 43rem;
  width: 40rem;
  margin: 0 auto;
  font-size: 1.5rem;
  .wx-img {
    width: 100%;
    height: 100%;
  }
}
.red-nav-button {
  color: red;
}

.red-nav-button:hover {
  background-color: red;
  color: white;
}
</style>
